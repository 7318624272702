<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室绩效数据填报</h2>
                <el-table :data="DeplisttableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="科室名称" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="updateDate" label="更新时间" align="center">
                    </el-table-column>
                    <el-table-column label="填报状态" align="center">
                        <template slot-scope="scope">
                            <div class="activeStatus" v-if="scope.row.businessStatus == 1">
                                √
                            </div>
                            <div class="noStatus" v-else>√</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="baseeditJixiao(scope.row)"><i class="el-icon-s-operation" style="font-size: 15px"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击操作kehsi基期数据填报 -->
        <el-dialog title="科室绩效数据填报" :visible.sync="baseperioddialogVisible" width="90%" :before-close="baseperioddialogVisibleClose" top="10vh">
            <perforInside :keshiId='keshiId' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import perforInside from "./performanceInside.vue";
export default {
    data() {
        return {
            // 科室基期数据填报弹出框
            baseperioddialogVisible: false,
            // 表格数据
            DeplisttableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            //这条数据id
            id: "",
            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 科室列表的id
            keshiId: "",
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            //这条数据id
            id: "",
            // 科室年份数据id
            YearId: "",
            name: "",
            type: "",
        };
    },
    components: {
        perforInside,
    },
    created() {
        this.getDepartmentList();
    },
    methods: {
        baseeditJixiao(row) {
            this.keshiId = row.id;
            this.baseperioddialogVisible = true;
        },
        baseperioddialogVisibleClose() {
            this.baseperioddialogVisible = false;
            this.pageNum = 1;
            this.getDepartmentList();
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getDepartmentList();
        },
        //   获取医院所属科室列表
        async getDepartmentList() {
            let data = {
                hospitalId: this.hospitalId,
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示数量
            };
            let { data: res } = await this.$axios.getDepartmentList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取医院所属科室列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.DeplisttableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.activeStatus {
    background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

.noStatus {
    background: #ccc;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
</style>
